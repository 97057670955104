/* Default editor style */
.custom-editor .ql-container {
	border: 1px solid #ccc; /* Default border for editing mode */
}

.custom-editor.read-only .ql-container {
	border: 0 !important; /* Remove border in read-only mode */
	min-height: 10px !important;
}

.custom-editor.read-only .ql-editor {
	padding: 4px 4px;
	min-height: 10px !important;
	// min-height: 60px;
	// height: 60px;
	overflow: hidden;
}
