@media (min-width: 576px) {
	.modal-xxl {
		min-width: 576px;
	}

	.blank-space {
		margin-top: 4px;
	}
}

@media (min-width: 768px) {
	.modal-xxl {
		min-width: 768px;
	}

	.blank-space {
		margin-top: 4px;
	}
}

@media (min-width: 992px) {
	.modal-xxl {
		min-width: 992px;
	}

	.blank-space {
		margin-top: 4px;
	}
}

@media (min-width: 1200px) {
	.modal-xxl {
		min-width: 1200px;
	}

	.blank-space {
		margin-top: 25px;
	}
}

@media (min-width: 1400px) {
	.modal-xxl {
		min-width: 1400px;
	}

	.blank-space {
		margin-top: 25px;
	}
}

@media (min-width: 1600px) {
	.modal-xxl {
		min-width: 1600px;
	}

	.blank-space {
		margin-top: 25px;
	}
}

.card-body {
	padding: 0.5rem 1.5rem;
}

.card-footer {
	padding: 0.5rem 1.5rem;
}

.board-description {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
	line-height: 1.5;
}
