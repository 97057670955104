.dropzone.container-box {
	border: 1px solid #eaeaea;
	padding: 10px;
	border-radius: 8px;
	background-color: #f9f9f9;
}

.dropzone.dropzone-style {
	border: 2px dashed #cccccc;
	padding: 2px;
	border-radius: 4px;
	text-align: center;
	display: grid;
	align-items: center;
	justify-content: center;
	min-height: 60px;
	margin-bottom: 16px;
}

.dropzone.thumbs-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.dropzone.thumb {
	display: inline-flex;
	border-radius: 2px;
	border: 1px solid #eaeaea;
	margin-bottom: 4px;
	margin-right: 4px;
	width: 100px;
	height: 100px;
	padding: 2px;
	box-sizing: border-box;
	position: relative;
}

.dropzone.thumb-inner {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 0;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.dropzone.img-preview {
	display: block;
	max-width: 100%;
	max-height: 100%;
	width: auto;
	height: auto;
}

.dropzone.delete-button {
	position: absolute;
	top: 2px;
	right: 2px;
	// background: red;
	// color: white;
	border: none;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	cursor: pointer;
	font-size: 12px;
	line-height: 20px;
	// text-align: center;
	padding: 0;
}
