.loading-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(100, 100, 100, 0.1);
	z-index: 9999;
	backdrop-filter: blur(2px);
}

.image-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.image-preview {
	width: 80px;
	height: 80px;
}
