.modal-preview {
	width: 100%;
	height: 600px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	position: relative;
}

.modal-image-preview {
	max-width: 100%;
	max-height: 100%;
	width: auto;
	height: auto;
	object-fit: contain;
}
