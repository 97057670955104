//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.min-wh-75 {
	min-width: 75vh;
}

.min-vh-75 {
	min-height: 75vh;
}

.quill {
	.ql-toolbar {
		border-top-left-radius: 1rem !important;
		border-top-left-radius: var(--bs-border-radius) !important;
		border-top-right-radius: 1rem !important;
		border-top-right-radius: var(--bs-border-radius) !important;
	}

	.ql-container {
		border-bottom-left-radius: 1rem !important;
		border-bottom-left-radius: var(--bs-border-radius) !important;
		border-bottom-right-radius: 1rem !important;
		border-bottom-right-radius: var(--bs-border-radius) !important;
	}
}
